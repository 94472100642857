import {
  Button,
  Col,
  DatePicker,
  FormInstance,
  Row,
  Spin,
  Table,
  TimePicker,
  Typography,
} from "antd";
import { color } from "echarts";
import { isUndefined } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import moment, { isMoment } from "moment";
import { useEffect, useState } from "react";

import {
  getDefaultColumnProps,
  IColumns,
  ISearch,
} from "../../../../app/common/table/utils";
import {
  IRequestGeneral,
  IRequestPack,
  IRequestStudy,
  IRequestStudyUpdate,
} from "../../../../app/models/request";
import { useStore } from "../../../../app/stores/store";
import alerts from "../../../../app/util/alerts";
import { catalog, status } from "../../../../app/util/catalogs";

const { Text } = Typography;
type RequestRequestProps = {
  formGeneral: FormInstance<IRequestGeneral>;
};

const RequestRequest = ({ formGeneral }: RequestRequestProps) => {
  const { requestStore } = useStore();
  const {
    readonly,
    request,
    setStudy,
    sendStudiesToRequest,
    isStudy,
    isPack,
    studies,
    packs,
    isUpdating,
    studyUpdate,
    scopes,
  } = requestStore;

  const [selectedStudiesAll, setSelectedStudiesAll] = useState<IRequestStudy[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [datasource, setDatasource] = useState<
    (IRequestStudy | IRequestPack)[]
  >([...studies, ...packs]);
  const [searchState, setSearchState] = useState<ISearch>({
    searchedText: "",
    searchedColumn: "",
  });
  const [selectedStudies, setSelectedStudies] = useState<
    { identificador: string }[]
  >([]);
  useEffect(() => {
    setSelectedStudiesAll([]);
    setSelectedStudies([]);
  }, []);

  useEffect(() => {
    setDatasource([]);
    setDatasource([...toJS(studies), ...toJS(packs)]);
  }, [packs, studies]);

  const updateDate = (item: IRequestStudy, value: moment.Moment | null) => {
    if (!value) {
      alerts.warning("Por favor selecciona una fecha");
      return;
    }

    // value = value.utcOffset(0, true);
    value = moment(value.format("YYYY-MM-DDTHH:mm:ss"));

    if (value.isBefore(moment().utcOffset(0, true))) {
      alerts.warning("No es posible seleccionar una fecha anterior");
      return;
    }

    setStudy({ ...item, fechaEntrega: value.format("YYYY-MM-DDTHH:mm:ss") });
    setDatasource([]);
    setDatasource([...toJS(studies), ...toJS(packs)]);
    studyUpdate.estudios = studies;
    studyUpdate.paquetes = packs;
    const origin = formGeneral.getFieldValue("urgencia");
    if (
      origin === catalog.urgency.normal &&
      moment(item.fechaEntrega).isAfter(value)
    ) {
      alerts.info("La solicitud se marcó como urgente");
      formGeneral.setFieldValue("urgencia", catalog.urgency.urgente);
      formGeneral.submit();
    }
  };



  const [selectedTime, setSelectedTime] = useState<moment.Moment>(moment("17:00", "HH:mm"));
  const [originalTimes, setOriginalTimes] = useState<{ [key: string]: string }>({});

  const updateTimeInDate = (date: string | moment.Moment, timeToApply: moment.Moment): string => {
    const momentDate = moment(date);
    return momentDate.clone()
      .set({
        hour: timeToApply.get('hour'),
        minute: timeToApply.get('minute')
      })
      .format("YYYY-MM-DDTHH:mm:ss");
  };

  useEffect(() => {
    if (selectedStudiesAll.length > 0) {
      const updatedStudies = selectedStudiesAll.map(study => ({
        ...study,
        fechaEntrega: updateTimeInDate(study.fechaEntrega, selectedTime)
      }));
      
      setSelectedStudiesAll(updatedStudies);
      setDatasource([]);
      setDatasource([...toJS(updatedStudies), ...toJS(packs)]);
    }
  }, [selectedTime]);

  const handleTimeChange = (time: moment.Moment | null) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const OnSelect = (
    request: IRequestStudy | IRequestPack,
    selected: boolean,
    subPackIgnore: boolean
  ) => {
    if (selected) {
      if (isStudy(request)) {
        const requestId = request.identificador ?? request.id?.toString() ?? "";

        if (!originalTimes[requestId] && request.estatus === "Toma de muestra") {
          const originalTime = typeof request.fechaEntrega === 'string'
            ? request.fechaEntrega
            : moment(request.fechaEntrega).format("YYYY-MM-DDTHH:mm:ss");

          setOriginalTimes(prev => ({
            ...prev,
            [requestId]: originalTime
          }));
        }

        const updatedRequest = {
          ...request,
          fechaEntrega: request.estatus === "Toma de muestra" 
            ? updateTimeInDate(request.fechaEntrega, selectedTime) 
            : request.fechaEntrega
        };
        setStudy(updatedRequest);

        const exists = selectedStudies.some(x =>
          x.identificador === (request.identificador ?? request.id?.toString())
        );

        if (!exists) {
          setSelectedStudiesAll(prev => [updatedRequest, ...prev]);
        }
      }

      const exists = selectedStudies.some(x =>
        x.identificador === (request.identificador ?? request.id?.toString())
      );

      if (!exists) {
        setSelectedStudies(prev => [...prev, {
          identificador: request.identificador ?? request?.id?.toString() ?? "",
        }]);
      }

      if (isPack(request)) {
        let currentPack = packs.find(o => o.id === request.id) ??
          packs.find(o => o.identificador === request.identificador);

        currentPack?.estudios
          .filter(es =>
            es.estatusId === status.requestStudy.solicitado ||
            es.estatusId === status.requestStudy.pendiente
          )
          .forEach(es => {
            OnSelect(es, true, false);
          });
      }
    } else {
      if (isStudy(request)) {
        const requestId = request.identificador ?? request.id?.toString() ?? "";
        const originalTime = originalTimes[requestId];

        if (originalTime && request.estatus === "Toma de muestra") {
          setStudy({ ...request, fechaEntrega: originalTime });
          setOriginalTimes(prev => {
            const newTimes = { ...prev };
            delete newTimes[requestId];
            return newTimes;
          });
        }
      }

      const id = request.identificador ?? request.id?.toString();
      setSelectedStudies(prev => prev.filter(x => x.identificador !== id));
      setSelectedStudiesAll(prev => prev.filter(x => x.identificador !== id));

      if (isPack(request) && !subPackIgnore) {
        let currentPack = packs.find(o => o.id === request.id) ??
          packs.find(o => o.identificador === request.identificador);

        currentPack?.estudios.forEach(es => {
          OnSelect(es, false, true);
        });
      } else if (!subPackIgnore) {
        packs.forEach(pa => {
          if (pa.estudios.some(o => o.identificador === request.identificador)) {
            OnSelect(pa, false, true);
          }
        });
      }
    }

    setDatasource([]);
    setDatasource([...toJS(studies), ...toJS(packs)]);
  };

  const getColumns = (isSubTable: boolean) => {
    let columnsStudies: IColumns<IRequestStudy | IRequestPack> = [];
    if (isSubTable) {
      columnsStudies = [
        {
          key: "taponColor",
          dataIndex: "taponColor",
          title: "",
          width: 48,
          align: "center",
          render: (value) => <ContainerBadge color={value} />,
        },
        ...columns,
      ];
      columnsStudies.push(
        {
          key: "taponColor",
          dataIndex: "taponColor",
          title: "",
          width: 43,
          align: "center",
          render: (value) => <ContainerBadge color={value} />,
        },
        Table.SELECTION_COLUMN
      );
      return columnsStudies;
    } else {
      return (columnsStudies = [
        ...columns,
        {
          key: "taponColor",
          dataIndex: "taponColor",
          title: "",
          width: 35,
          align: "center",
          render: (value) => <ContainerBadge color={value} />,
        },
        Table.SELECTION_COLUMN,
      ]);
    }
  };

  const columns: IColumns<IRequestStudy | IRequestPack> = [
    {
      ...getDefaultColumnProps("clave", "Clave", {
        searchState,
        setSearchState,
        width: "15%",
      }),
      render: (value, item) => {
        if (isPack(item)) {
          return (
            <Text type="secondary" strong>
              {item.clave}
            </Text>
          );
        } else {
          return <Text>{item.clave}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("nombre", "Estudio", {
        searchState,
        setSearchState,
        width: "35%",
      }),
      ellipsis: {
        showTitle: false,
      },
      render: (value, item) => {
        if (isPack(item)) {
          return (
            <Text type="secondary" strong>
              {item.nombre}
            </Text>
          );
        } else {
          return <Text>{item.nombre}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("estatus", "Estatus", {
        searchState,
        setSearchState,
        width: "10%",
      }),
      render: (value) => {
        if (value === "Cancelado") return <Text type="danger">{value}</Text>;
        else return <Text>{value}</Text>;
      },
    },
    {
      ...getDefaultColumnProps("dias", "Días", {
        searchable: false,
        width: "10%",
      }),
      render: (value, item) => {
        if (isPack(item)) {
          return (
            <Text type="secondary" strong>
              {item.dias}
            </Text>
          );
        } else {
          return <Text>{item.dias}</Text>;
        }
      },
    },
    {
      ...getDefaultColumnProps("fechaSolicitado", "Fecha solicitado", {
        searchable: false,
        width: "15%",
      }),
    },
    {
      ...getDefaultColumnProps("fechaEntrega", "Fecha de entrega", {
        searchable: false,
        width: "15%",
      }),
      className: "no-padding-cell",
      render: (value, item) => {
        if (isStudy(item)) {
          const st = status.requestStudy;
          // prettier-ignore
          const validStatus =
            item.estatusId === st.pendiente || item.estatusId === st.tomaDeMuestra || item.estatusId === st.solicitado || item.estatusId === st.enRuta;
          return (
            <DatePicker
              getPopupContainer={(trigger: any) => trigger.parentNode}
              bordered={false}
              value={
                value
                  ? isMoment(value)
                    ? value
                    : moment(value.replace("Z", ""))
                  : moment().utcOffset(0, true)
              }
              format="DD/MM/YYYY HH:mm"
              minuteStep={5}
              showTime
              disabled={readonly || !validStatus}
              allowClear={false}
              onChange={(value) => {
                updateDate(item, value);
              }}
              disabledDate={(current) =>
                current.isBefore(moment().utcOffset(0, true))
              }
            />
          );
        }
      },
    },
  ];

  //Expandible
  const expandableConfig = () => {
    const isIRequestPack = (
      record: IRequestStudy | IRequestPack
    ): record is IRequestPack => {
      return (record as IRequestPack).type === "pack";
    };

    return {
      expandedRowRender: (
        item: IRequestStudy | IRequestPack,
        index: number
      ) => {
        if (isIRequestPack(item)) {
          return (
            <Table
              rowKey={(record) => `${record.identificador ?? record.id}`}
              columns={getColumns(true)}
              dataSource={item.estudios}
              pagination={false}
              className="tableEstudios"
              showHeader={false}
              style={{ padding: 0 }}
              rowSelection={{
                fixed: "right",
                onSelect(request, selected) {
                  OnSelect(request, selected, false);
                },

                selectedRowKeys: selectedStudies.map((x) => x.identificador),

                getCheckboxProps: (record) => ({
                  disabled:
                    (readonly ||
                      (record.estatusId !== status.requestStudy.tomaDeMuestra &&
                        record.estatusId !== status.requestStudy.solicitado)) &&
                    isStudy(record),
                }),
              }}
            />
          );
        }
        return null;
      },
      rowExpandable: (record: IRequestStudy | IRequestPack) => {
        return isIRequestPack(record);
      },
      defaultExpandAllRows: true,
    };
  };

  const updateStudies = async () => {
    if (request) {
      const data: IRequestStudyUpdate = {
        expedienteId: request.expedienteId,
        solicitudId: request.solicitudId!,
        estudios: selectedStudiesAll,
      };
      setLoading(true);
      const ok = await sendStudiesToRequest(data);
      if (ok) {
        setSelectedStudies([]);
        setSelectedStudiesAll([]);
      }
      setLoading(false);
    }
  };


  return (
    <Spin spinning={loading}>
      <Row gutter={[8, 12]}>
        {!readonly && scopes.modificar && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="default"
              disabled={
                selectedStudiesAll.length === 0 ||
                !selectedStudiesAll.every(
                  (x) => x.estatusId === status.requestStudy.solicitado
                )
              }
              onClick={updateStudies}
            >
              Cancelar
            </Button>
            <Button
              type="default"
              disabled={
                selectedStudiesAll.length === 0 ||
                !selectedStudiesAll.every(
                  (x) => x.estatusId === status.requestStudy.tomaDeMuestra
                )
              }
              onClick={updateStudies}
            >
              Solicitar estudio
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Table<IRequestStudy | IRequestPack>
            size="small"
            rowKey={(record) => `${record.identificador ?? record.id}`}
            columns={getColumns(false)}
            dataSource={datasource}
            pagination={false}
            className="tableEstudios"
            rowSelection={{
              fixed: "right",
              onSelect(request, selected) {
                OnSelect(request, selected, false);
              },
              onChange(_, selectedRows, info) {
                if (info.type === "all") {
                  if (selectedRows.length === 0) {
                    setSelectedStudies([]);
                    setSelectedStudiesAll([]);
                  } else {
                    let studiesList: IRequestStudy[] = [];
                    let list: { identificador: string }[] = [];

                    packs.forEach((pack) => {
                      const someStudyValid = pack.estudios.some(
                        (study) =>
                          study.estatusId === status.requestStudy.solicitado ||
                          study.estatusId === status.requestStudy.tomaDeMuestra
                      );

                      if (someStudyValid) {
                        list.push({
                          identificador:
                            pack.identificador ?? pack.id?.toString() ?? "",
                        });

                        pack.estudios.forEach((study) => {
                          if (
                            study.estatusId ===
                            status.requestStudy.solicitado ||
                            study.estatusId ===
                            status.requestStudy.tomaDeMuestra
                          ) {
                            list.push({
                              identificador:
                                study.identificador ??
                                study.id?.toString() ??
                                "",
                            });
                            studiesList.push(study);
                          }
                        });
                      }
                    });

                    studies.forEach((study) => {
                      if (
                        study.estatusId === status.requestStudy.solicitado ||
                        study.estatusId === status.requestStudy.tomaDeMuestra
                      ) {
                        list.push({
                          identificador:
                            study.identificador ?? study.id?.toString() ?? "",
                        });
                        studiesList.push(study);
                      }
                    });

                    setSelectedStudiesAll((prev) => [
                      ...prev.filter((x) =>
                        studiesList.some(
                          (y) => y.identificador === x.identificador
                        )
                      ),
                      ...studiesList,
                    ]);
                    setSelectedStudies(list);
                  }
                }
              },
              getCheckboxProps: (record) => ({
                disabled:
                  (readonly ||
                    (record.estatusId !== status.requestStudy.tomaDeMuestra &&
                      record.estatusId !== status.requestStudy.solicitado)) &&
                  scopes.modificar &&
                  isStudy(record),
              }),

              selectedRowKeys: selectedStudies.map((x) => x.identificador),
            }}
            sticky
            // scroll={{ x: "fit-content" }}
            expandable={expandableConfig()}
          />
        </Col>
      </Row>
      <Row gutter={[8, 12]} align="middle" justify="end">
        <Col style={{ textAlign: "right" }}>
          <Typography.Text strong>Hora de entrega:</Typography.Text>
        </Col>
        <Col>
          <TimePicker
            getPopupContainer={(trigger: any) => trigger.parentNode}
            bordered={false}
            format="HH:mm"
            minuteStep={5}
            allowClear={false}
            value={selectedTime}
            onSelect={handleTimeChange}
            popupStyle={{ width: 150 }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

const ContainerBadge = ({ color }: { color: string }) => {
  return (
    <div className="badge-container" style={{ backgroundColor: color }}></div>
  );
};
export default observer(RequestRequest);
