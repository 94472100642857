import { makeAutoObservable } from "mobx";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import { getErrors } from "../util/utils";
import shipmentTracking from "../api/shipmentTracking";
import {
  IReceiveTags,
  IReceiveTracking,
  IShipmentTags,
  IShipmentTracking,
} from "../models/shipmentTracking";
import {
  IStudyTrackinOrder,
  IRouteTrackingForm,
} from "../models/trackingOrder";

export default class shipmentTrackingStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  shipment?: IShipmentTracking;
  history?: IShipmentTracking;
  receive?: IReceiveTracking;

  receiveTags: IReceiveTags[] = [];
  historyTags: IShipmentTags[] = [];
  // receiveStudies: IStudyTrackinOrder[] = [];

  newRouteTrackingForm: IRouteTrackingForm | undefined;

  loadingOrders: boolean = false;
  receiveScan: boolean = false;

  setNewRouteTrackingForm = (newRouteTrackingForm: IRouteTrackingForm) => {
    this.newRouteTrackingForm = newRouteTrackingForm;
  };

  clearNewRouteTrackingForm = () => {
    this.newRouteTrackingForm = undefined;
  };

  setReceiveTags = (receiveTags: IReceiveTags[]) => {
    this.receiveTags = receiveTags;
  };

  setHistoryTags = (historyTags: IShipmentTags[]) => {
    this.historyTags = historyTags;
  };

  setLoadingOrder = (loading: boolean) => {
    this.loadingOrders = loading;
  };

  // setReceiveStudies = (receiveStudies: IStudyTrackinOrder[]) => {
  //   this.receiveStudies = receiveStudies;
  // };

  setReceiveScan = (receiveScan: boolean) => {
    this.receiveScan = receiveScan;
  };

  clearScopes = () => {
    this.scopes = new Scopes();
  };

  getStudyTrackingOrder = (record: IReceiveTags) => {
    const studyTrackingOrder: IStudyTrackinOrder = {
      id: record.id,
      etiquetaId: record.etiquetaId,
      solicitudId: record.solicitudId,
      claveRuta: record.claveRuta,
      escaneo: record.escaneoEntrega,
      extra: record.extra,
    };
    return studyTrackingOrder;
  };

  access = async () => {
    try {
      const scopes = await shipmentTracking.access();
      this.scopes = scopes;
    } catch (error) {
      alerts.warning(getErrors(error));
      //history.push("/forbidden");
    }
  };

  onScan = async (value: boolean, id?: number) => {
    const tags = [...this.receiveTags];

    this.updateReceiveTags(tags, value, id);
    this.setReceiveTags(tags);
  };

  updateReceiveTags = (tags: IReceiveTags[], value: boolean, id?: number) => {
    if (id) {
      const index = tags.findIndex((x) => x.etiquetaId === id);
      tags[index].escaneoEntrega = value;
    } else {
      tags.forEach((x) => {
        x.escaneoEntrega = value;
      });
    }
  };

  onSelectAll = async (value: boolean, id?: number) => {
    const tags = [...this.receiveTags];

    this.updateReceiveTagsALL(tags, value, id);
    this.setReceiveTags(tags);
  };

  updateReceiveTagsALL = (tags: IReceiveTags[], value: boolean, id?: number) => {
    if (id) {
      const index = tags.findIndex((x) => x.etiquetaId === id);
      tags[index].escaneoEntrega = value;
    } else {
      tags.forEach((x) => {
        x.escaneoEntrega = value;
      });
    }
  };

  getShipmentById = async (id: string) => {
    try {
      this.loadingOrders = true;
      const shipmentOrder = await shipmentTracking.getShipmentById(id);
      this.shipment = shipmentOrder;
      return shipmentOrder;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingOrders = false;
    }
  };

  getReceiveById = async (id: string) => {
    try {
      this.loadingOrders = true;
      const receiveOrder = await shipmentTracking.getReceiveById(id);
      this.receive = receiveOrder;
      return receiveOrder;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingOrders = false;
    }
  };

  getHistoryById = async (id: string) => {
    try {
      this.loadingOrders = true;
      const shipmentOrder = await shipmentTracking.getHistoryById(id);
      this.shipment = shipmentOrder;
      return shipmentOrder;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingOrders = false;
    }
  };

  createNewTrackingOrder = async (trackingOrder: IReceiveTracking) => {
    try {
      this.loadingOrders = true;
      const order = await shipmentTracking.createNewTrackingOrder(
        trackingOrder
      );
      return order;
    } catch (error) {
      alerts.warning(getErrors(error));
    } finally {
      this.loadingOrders = false;
    }
  };

  updateReceive = async (receiveData: IReceiveTracking) => {
    try {
      this.loadingOrders = true;
      await shipmentTracking.updateReceive(receiveData);
      alerts.success("Orden de seguimiento entregada exitosamente");
      return true;
    } catch (error) {
      alerts.warning(getErrors(error));
      return false;
    } finally {
      this.loadingOrders = false;
    }
  };

  printTicket = async (id: string) => {
    try {
      var response = await shipmentTracking.getById(id);
      await shipmentTracking.exportList(response);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
