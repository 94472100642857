import { makeAutoObservable } from "mobx";
import Report from "../api/report";
import {
  IReportFilter,
  IReportData,
  ReportFilterValues,
} from "../models/report";
import { IScopes, Scopes } from "../models/shared";
import alerts from "../util/alerts";
import history from "../util/history";
import { getErrors } from "../util/utils";
import { reportType } from "../../components/report/utils";
import moment from "moment";

type tableMetadataType = {
  columnKey: string;
  order: string;
  searchText: string;
  searchColumn: string;
};

export default class ReportStore {
  constructor() {
    makeAutoObservable(this);
  }

  scopes: IScopes = new Scopes();
  currentReport?: reportType;
  filter: IReportFilter = new ReportFilterValues();
  reportData: IReportData[] = [];
  chartData: any[] = [];
  tableData: any[] = [];
  loadingReport: boolean = false;
  detail: boolean = false;
  tableMetadata: tableMetadataType = { columnKey: "", order: "", searchText: "",searchColumn: "" };

  setTableMetadata = (columnKey: string, order: string, searchText: string = "", searchColumn: string = "") => {
    this.tableMetadata = { columnKey, order, searchText, searchColumn };
  };

  clearScopes = () => {
    this.scopes = new Scopes();
  };

  setCurrentReport = (name?: reportType) => {
    this.currentReport = name;
  };

  setDetail = (detail: boolean) => {
    this.detail = detail;
  };

  setFilter = (filter: IReportFilter) => {
    this.filter = filter;
  };

  access = async () => {
    try {
      const scopes = await Report.access();
      this.scopes = scopes;
      return scopes;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      history.push("/forbidden");
    }
  };

  getByFilter = async (report: string, filter: IReportFilter) => {
    try {
      this.loadingReport = true;
      filter.search = this.tableMetadata.searchText;
      filter.searchColumn = this.tableMetadata.searchColumn;
      const data = await Report.getByFilter(report, filter);
      this.reportData = data;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      this.reportData = [];
    } finally {
      this.loadingReport = false;
    }
  };

  getByChart = async <T extends unknown>(
    report: string,
    filter: IReportFilter
  ) => {
    try {
      this.loadingReport = true;
      const data = await Report.getByChart<T>(report, filter);
      this.chartData = data;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      this.reportData = [];
    } finally {
      this.loadingReport = false;
    }
  };

  getByTable = async <T extends unknown>(
    report: string,
    filter: IReportFilter
  ) => {
    try {
      this.loadingReport = true;
      const data = await Report.getByTable<T>(report, filter);
      this.tableData = data;
    } catch (error: any) {
      alerts.warning(getErrors(error));
      this.reportData = [];
    } finally {
      this.loadingReport = false;
    }
  };

  printPdf = async (report: string, filter: IReportFilter) => {
    try {
      filter.columnKey = this.tableMetadata.columnKey;
      filter.order = this.tableMetadata.order;
      filter.searchColumn = this.tableMetadata.searchColumn;
      filter.search = this.tableMetadata.searchText;
      await Report.printPdf(report, filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
  
  printExcel = async (report: string, filter: IReportFilter) => {
    try {
      filter.columnKey = this.tableMetadata.columnKey;
      filter.order = this.tableMetadata.order;
      await Report.printExcel(report, filter);
    } catch (error: any) {
      alerts.warning(getErrors(error));
    }
  };
}
