import { Button, Col, Form, Input, Row } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";

import DateRangeInput from "../../app/common/form/proposal/DateRangeInput";
import SelectInput from "../../app/common/form/proposal/SelectInput";
import TextInput from "../../app/common/form/proposal/TextInput";
import { IGeneralForm } from "../../app/models/general";
import { IOptions } from "../../app/models/shared";
import { originOptions, studyStatusOptions, urgencyOptions } from "../../app/stores/optionStore";
import { useStore } from "../../app/stores/store";
import { formItemLayout } from "../../app/util/utils";

const DeliveryResultsForm = () => {
  const [form] = Form.useForm();
  const { optionStore, deliveryResultsStore, profileStore, generalStore } = useStore();

  const {
    // branchCityOptions,
    medicOptions,
    companyOptions,
    areaByDeparmentOptions,
    userBranchesOptions,
    // getCityOptions,
    getMedicOptions,
    getCompanyOptions,
    getDepartmentOptions,
    // getBranchOptions,
    getAreaByDeparmentOptions,
    getUserBranchesOptions,
  } = optionStore;
  const { profile } = profileStore;
  const { getAllCaptureResults } = deliveryResultsStore;
  const { generalFilter, setGeneralFilter } = generalStore;

  const selectedCity = Form.useWatch("ciudad", form);
  const selectedDepartment = Form.useWatch("departamento", form);
  const [cityOptions, setCityOptions] = useState<IOptions[]>([]);
  const [branchOptions, setBranchOptions] = useState<IOptions[]>([]);
  const [areaOptions, setAreaOptions] = useState<IOptions[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    getMedicOptions();
    getCompanyOptions();
    getDepartmentOptions();
    getAreaByDeparmentOptions();
    // getBranchOptions();
    // getCityOptions();
  }, [getMedicOptions, getCompanyOptions, getDepartmentOptions, getAreaByDeparmentOptions]);

  useEffect(() => {
    if (userBranchesOptions.length === 0) getUserBranchesOptions(profile!.sucursal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCityOptions(userBranchesOptions.map((x) => ({ value: x.value, label: x.label })));
  }, [userBranchesOptions]);

  useEffect(() => {
    if (selectedCity != null) {
      var branhces = userBranchesOptions.filter((x) => selectedCity.includes(x.value.toString()));
      var options = branhces.flatMap((x) => (x.options === undefined ? [] : x.options));
      setBranchOptions(options);
    }
  }, [userBranchesOptions, form, selectedCity]);

  useEffect(() => {
    setDepartmentOptions(areaByDeparmentOptions.map((x) => ({ value: x.value, label: x.label })));
  }, [areaByDeparmentOptions]);

  useEffect(() => {
    setAreaOptions(
      areaByDeparmentOptions
        .filter((x) => selectedDepartment?.includes(x.value as number))
        .flatMap((x) => x.options ?? [])
    );
  }, [areaByDeparmentOptions, form, selectedDepartment]);

  useEffect(() => {
    if (!profile || !profile.sucursal || userBranchesOptions.length === 0) return;

    const profileBranch = profile.sucursal;
    const userCity = userBranchesOptions
      .find((x) => x.options!.some((y) => y.value === profileBranch))
      ?.value?.toString();

    const filter = {
      ...generalFilter,
      ciudad: !generalFilter.cargaInicial ? generalFilter.ciudad : [userCity as string],
      sucursalId: !generalFilter.cargaInicial ? generalFilter.sucursalId : [profileBranch],
    };
    filter.fecha =
      filter.fecha == null
        ? undefined
        : [filter.fecha[0].utcOffset(0, true), filter.fecha[1].utcOffset(0, true)];

    form.setFieldsValue(filter);
    filter.cargaInicial = false;

    setGeneralFilter(filter);
    getAllCaptureResults(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBranchesOptions]);

  const onFinish = async (values: IGeneralForm) => {
    if (values.fecha && values.fecha.length === 2) {
      values.fecha[0] = values.fecha[0].utcOffset(0, true);
      values.fecha[1] = values.fecha[1].utcOffset(0, true);
    }

    if (values.ciudad == null) values.ciudad = [];
    if (values.sucursalId == null) values.sucursalId = [];

    if (values.ciudad.length > 0 && values.sucursalId.length === 0) {
      values.sucursalId = userBranchesOptions
        .filter((x) => values.ciudad!.includes(x.value as string))
        .flatMap((x) => (x.options ?? []).map((b) => b.value as string));
    }

    await getAllCaptureResults(values);
    setGeneralFilter(values);
  };

  return (
    <div className="status-container" style={{ marginBottom: 6}}>
      <Form<IGeneralForm>
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
        size="small"
        initialValues={{
          fecha: [moment().utcOffset(0, true), moment().utcOffset(0, true)],
          tipoFecha: 1,
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            event.stopPropagation();
            form.submit();
          }
        }}
      >
        <Row gutter={[0, 12]} style={{rowGap:1}}>
          <Col span={8}>
            <SelectInput
              formProps={{
                name: "tipoFecha",
                label: "Fecha de",
              }}
              options={[
                { value: 1, label: "Fecha de Creación" },
                { value: 2, label: "Fecha de Entrega" },
              ]}
            />
          </Col>
          <Col span={8}>
            <DateRangeInput formProps={{ label: "Fechas", name: "fecha" }} />
          </Col>

          <Col span={8}>
            <TextInput formProps={{ name: "buscar", label: "Clave/Paciente" }} />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{ label: "Compañias", name: "compañiaId" }}
              options={companyOptions}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{
                label: "Medios de entrega",
                name: "mediosEntrega",
              }}
              options={[
                { value: "Whatsapp", label: "Whatsapp" },
                { value: "Correo", label: "Correo" },
                { value: "Fisico", label: "Físico" },
              ]}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{
                label: "Tipo de solicitud",
                name: "tipoSolicitud",
              }}
              options={urgencyOptions}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{ label: "Estatus", name: "estatus" }}
              options={studyStatusOptions}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{ label: "Médicos", name: "medicoId" }}
              options={medicOptions}
            />
          </Col>

          <Col span={8}>
            <Form.Item label="Sucursal" className="no-error-text" help="">
              <Input.Group>
                <Row gutter={8}>
                  <Col span={12}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "ciudad",
                        label: "Ciudad",
                        noStyle: true,
                      }}
                      multiple
                      options={cityOptions}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "sucursalId",
                        label: "Sucursales",
                        noStyle: true,
                      }}
                      multiple
                      options={branchOptions}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Áreas" className="no-error-text" help="">
              <Input.Group>
                <Row gutter={8}>
                  <Col span={12}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "departamento",
                        label: "Departamento",
                        noStyle: true,
                      }}
                      multiple
                      options={departmentOptions}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      form={form}
                      formProps={{
                        name: "area",
                        label: "Área",
                        noStyle: true,
                      }}
                      multiple
                      options={areaOptions}
                    />
                  </Col>
                </Row>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <SelectInput
              form={form}
              multiple
              formProps={{ label: "Procedencias", name: "procedencia" }}
              options={originOptions}
            />
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button key="clean" htmlType="reset">
              Limpiar
            </Button>
            <Button key="filter" type="primary" htmlType="submit">
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default observer(DeliveryResultsForm);
