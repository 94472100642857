import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";

import { IGeneralForm } from "../../app/models/general";
import { useStore } from "../../app/stores/store";
import ClinicResultsFilter from "./ClinicResultsFilter";
import ClinicResultsTable from "./ClinicResultsTable";
import ClinicResultsColumns, { ClinicResultsExpandable } from "./columnDefinition/clinicResults";

type CRDefaultProps = {
  printing: boolean;
  formValues: IGeneralForm;
};

const ClinicResultsBody = ({ printing, formValues }: CRDefaultProps) => {
  const { procedingStore, clinicResultsStore, samplingStudyStore } = useStore();
  const { scopes: recordScopes } = procedingStore;
  const { printOrderAsPdf } = samplingStudyStore;
  const { data, scopes } = clinicResultsStore;
  const [loading] = useState(false);

  const printOrder = async (expedienteId: string, id: string) => {
    const blob = await printOrderAsPdf(expedienteId, id);
    window.open(blob, '_blank');
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <ClinicResultsFilter />
      </div>
      <div className="TablaCapturaResultados" style={{ flex: "1 1 0%" }}>
        {/* <Spin spinning={loading || printing} tip={printing ? "Descargando" : ""}> */}
        <ClinicResultsTable
          data={data}
          loading={loading || printing}
          columns={ClinicResultsColumns({ recordScopes, printOrder, scopes })}
          expandable={ClinicResultsExpandable()}
        />
        {/* </Spin> */}
      </div>
    </div>
  );
};

export default observer(ClinicResultsBody);
