import {
  Spin,
  Row,
  Col,
  Pagination,
  Divider,
  PageHeader,
  Tag,
  Form,
  Button,
  Typography,
  Select,
  Space,
} from "antd";
import { observer } from "mobx-react-lite";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import DescriptionItem from "../../../app/common/display/DescriptionItem";
import moment from "moment";
import TrackingTimeline from "../content/TrackingTimeline";
import ShipmentTrackingTitle from "../shipment/content/ShipmentTrackingTitle";
import {
  IReceiveTags,
  IReceiveTracking,
  ReceiveTrackingFormValues,
} from "../../../app/models/shipmentTracking";
import SwitchInput from "../../../app/common/form/proposal/SwitchInput";
import NumberInput from "../../../app/common/form/proposal/NumberInput";
import { formItemLayout, getDistinct, groupBy } from "../../../app/util/utils";
import ReceiveTrackingTable from "./ReceiveTrackingTable";
import { IOptions } from "../../../app/models/shared";
import alerts from "../../../app/util/alerts";
import { IRouteTrackingForm } from "../../../app/models/trackingOrder";
import { toJS } from "mobx";
import { status } from "../../../app/util/catalogs";
import TextInput from "../../../app/common/form/proposal/TextInput";

const { Text } = Typography;

type StudyTableProps = {
  componentRef: React.MutableRefObject<any>;
  printing: boolean;
};
type UrlParams = {
  id: string;
};

const ReceiveTrackingDetail: FC<StudyTableProps> = ({
  componentRef,
  printing,
}) => {
  const { routeTrackingStore, shipmentTrackingStore } = useStore();
  const { getActive, trackingOrders, scopes } = routeTrackingStore;
  const {
    getReceiveById,
    receive,
    loadingOrders,
    // receiveStudies,
    // setReceiveStudies,
    onScan,
    onSelectAll,
    setReceiveScan,
    receiveScan,
    receiveTags,
    setReceiveTags,
    setNewRouteTrackingForm,
    updateReceive,
    getStudyTrackingOrder,
    scopes: receiveScopes,
  } = shipmentTrackingStore;

  const navigate = useNavigate();
  const [form] = Form.useForm<IReceiveTracking>();

  const { id } = useParams<UrlParams>();
  const [values, setValues] = useState<IReceiveTracking>(
    new ReceiveTrackingFormValues()
  );
  const [destinationOptions, setDestinationOptions] = useState<IOptions[]>([]);
  const [selectedRows, setSelectedRows] = useState<IReceiveTags[]>([]);
  const [selectedDestination, setSelectedDestination] = useState<IOptions>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [notScanned, setNotScanned] = useState<string>();
  const [notRecolected, setNotRecolected] = useState<boolean>(true);

  const [startScan, setStartScan] = useState<boolean>(false);

  useEffect(() => {
    let readshipment = async (id: string) => {
      let receive = await getReceiveById(id);

      if (receive) {
        setReceiveTags(receive.etiquetas);

        setValues(receive);
        form.setFieldsValue(receive);
      }
    };

    if (id) {
      readshipment(id);
    }
  }, [getReceiveById, id]);

  useEffect(() => {
    const readOrders = async () => {
      await getActive();
    };

    readOrders();
  }, [getActive]);

  useEffect(() => {
    form.setFieldValue("escaneoEntrega", receiveScan);
  }, [receiveScan]);

  useEffect(() => {
    const destinations = selectedRows.flatMap((x) => x.destinos) ?? [];
    if (selectedRows.length === 1 && destinations) {
      const options = destinations.map((x) => ({
        label: x.nombreRuta,
        value: x.rutaId,
        extra: {
          destinoId: !!x.destinoId ? x.destinoId : x.rutaId,
          claveRuta: x.claveRuta,
        },
      }));
      setDestinationOptions(options);
    } else if (selectedRows.length > 1) {
      const repeatedOptions = groupBy(destinations, "rutaId")
        .filter((x) => x.items.length > 1)
        .flatMap((x) => x.items);

      const options = getDistinct(repeatedOptions).map((x) => ({
        label: x.nombreRuta,
        value: x.rutaId,
        extra: { destinoId: x.destinoId, claveRuta: x.claveRuta },
      }));

      setDestinationOptions(options);
    } else {
      setDestinationOptions([]);
      setSelectedDestination(undefined);
    }
  }, [selectedRows]);

  const onPageChange = (page: number) => {
    const trackingOrder = trackingOrders[page - 1];
    navigate(`/receiveTracking/${trackingOrder.id}`);
  };

  const getPage = (trackingOrderId?: string) => {
    return trackingOrders.findIndex((x) => x.id === trackingOrderId) + 1;
  };

  const onDestinationChange = (
    value: string,
    option: IOptions | IOptions[]
  ) => {
    setSelectedDestination(option as IOptions);
  };

  const createTrackingOrder = async (newValues: IReceiveTracking) => {
    const confirmationTitle =
      "¿Está seguro de crear una nueva orden de rastreo y aceptar la orden actual?";
    const confirmationMessage = "Esta acción no se puede deshacer";

    const handleCreateTrackingOrder = async () => {
      if (receive) {
        const newTrackingOrder = {
          diaRecoleccion: moment().utcOffset(0, true),
          fechaMuestra: [
            moment(receive.fechaEnvio),
            moment().utcOffset(0, true),
          ],
          origenId: receive.destinoId,
          destino: selectedDestination?.extra.destinoId as string,
          etiquetas: selectedRows.map((x) => ({
            id: x.id,
            claveEtiqueta: x.claveEtiqueta,
            recipiente: x.recipiente,
            claveRuta: selectedDestination?.extra?.claveRuta,
            solicitud: x.solicitud,
            paciente: x.paciente!,
            solicitudId: x.solicitudId,
            etiquetaId: x.etiquetaId,
            escaneo: x.escaneo,
            rutaId: selectedDestination?.value,
            cantidad: x.cantidad,
            estatus: status.requestStudy.solicitado,
            extra: x.extra,
            estudios: x.estudios,
            estudiosId: [],
            tipoDestino: x.tipoDestino,
          })),
          solicitudes: [],
          rutaId: selectedDestination?.value as string,
          estatus: 0,
          temperatura: 0,
          activo: true,
          escaneo: false,
          estudios: [],
        };
        setNewRouteTrackingForm(newTrackingOrder);
      }

      const success = await continueAcceptDelivery(newValues);
      if (success) navigate(`/trackingOrder/new`);
    };

    alerts.confirm(
      confirmationTitle,
      confirmationMessage,
      handleCreateTrackingOrder
    );
  };

  const continueAcceptDelivery = async (newValues: IReceiveTracking) => {
    const delivered = { ...values, newValues, id: id! };
    delivered.estudios = receiveTags
      .filter((x) => x.escaneoEntrega)
      .map(getStudyTrackingOrder)
      .map((x) => ({ ...x, confimacionDestino: true }));
    delivered.etiquetas = receiveTags
      .filter((x) => x.escaneoEntrega)
      .map((x) => ({ ...x, confirmacionDestino: true }));
    delivered.temperaturaEntrega = form.getFieldValue("temperaturaEntrega");

    setValues(delivered);

    const success = id ? await updateReceive(delivered) : false;
    if (success) setReceiveScan(false);

    return success;
  };

  const onFinish = async (newValues: IReceiveTracking) => {
    if (selectedRows.length > 0) {
      await createTrackingOrder(newValues);
    } else {
      const confirmationTitle =
        "¿Está seguro de que quieres aceptar la orden de rastreo?";
      const confirmationMessage = "Esta acción no se puede deshacer";
      alerts.confirm(confirmationTitle, confirmationMessage, async () => {
        const success = await continueAcceptDelivery(newValues);
        if (success) navigate("/segRutas");
      });
    }
  };

  const checkIfMultiplePoints =
    receive?.etiquetas.some((x) => x.destinos) && selectedRows.length > 0;

  return (
    <Fragment>
      <Spin spinning={loadingOrders}>
        <Row gutter={[24, 16]}>
          <Col md={8} sm={24} xs={12} style={{ textAlign: "left" }}>
            <Pagination
              simple
              key="pagination"
              size="small"
              total={trackingOrders.length}
              pageSize={1}
              current={getPage(id)}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </Col>
          <Col span={16}>
            <ShipmentTrackingTitle
              shipment={receive}
              tipoEnvio="receive"
              scopes={scopes}
            />
          </Col>
          <Col md={12}>
            <div className="tracking-card">
              <Row gutter={[0, 4]}>
                <Col md={24}>
                  <PageHeader
                    title="Origen"
                    className="header-container-padding"
                    tags={
                      <Tag color="blue">
                        {receive?.activo ? "Activo" : "Inactivo"}
                      </Tag>
                    }
                    avatar={{
                      src: `${process.env.REACT_APP_NAME}/assets/origen.png`,
                    }}
                  ></PageHeader>
                </Col>
                <Divider className="header-divider"></Divider>
                <Col md={8}>
                  <DescriptionItem title="Sucursal" content={receive?.origen} />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Responsable de envío"
                    content={receive?.emisor}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Medio de entrega"
                    content={receive?.paqueteria}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Fecha de envío"
                    content={moment(receive?.fechaEnvio).format("DD/MM/YYYY")}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Hora de envío"
                    content={moment(receive?.fechaEnvio).format("h:mmA")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={12}>
            <div className="tracking-card">
              <Row gutter={[0, 4]}>
                <Col md={24}>
                  <PageHeader
                    title="Destino"
                    className="header-container-padding"
                    avatar={{
                      src: `${process.env.REACT_APP_NAME}/assets/destino.png`,
                      shape: "square",
                    }}
                  ></PageHeader>
                </Col>
                <Divider className="header-divider"></Divider>
                <Col md={8}>
                  <DescriptionItem
                    title="Sucursal"
                    content={receive?.destino}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Responsable de recibido"
                    content={receive?.receptor ?? "N/A"}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Fecha de entrega estimada"
                    content={moment(receive?.fechaEstimada).format(
                      "DD/MM/YYYY"
                    )}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Hora de entrega estimada"
                    content={moment(receive?.fechaEstimada).format("h:mmA")}
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Fecha de entrega real"
                    content={
                      receive?.fechaReal
                        ? moment(receive?.fechaEstimada).format("DD/MM/YYYY")
                        : "N/A"
                    }
                  />
                </Col>
                <Col md={8}>
                  <DescriptionItem
                    title="Hora de entrega real"
                    content={
                      receive?.fechaReal
                        ? moment(receive?.fechaReal).format("h:mmA")
                        : "N/A"
                    }
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <TrackingTimeline estatus={receive?.estatus} />
          </Col>
          {notScanned && !notRecolected ? (
            <Col span={24}>
              <Text type="danger">{`Muestras pendientes: ${notScanned}`}</Text>
            </Col>
          ) : notRecolected ? (
            <Col span={24}>
              <Text type="danger">
                Las muestras debe ser recolectadas previamente.
              </Text>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <br />
        {!receive?.fechaReal ? (
          <Form<IReceiveTracking>
            {...formItemLayout}
            form={form}
            name="trackingOrder"
            initialValues={values}
            onFinish={onFinish}
            scrollToFirstError
            labelWrap
          >
            <Row gutter={[4, 16]} justify="space-between" align="middle">
              <Col md={6} sm={12}>
                <NumberInput
                  formProps={{
                    name: "temperaturaEntrega",
                    label: "Temperatura",
                  }}
                  type="number"
                  suffix="°C"
                  readonly={receive?.estatus! > 3}
                  controls={false}
                />
              </Col>
              <Fragment>
                <Col md={6} sm={12}>
                  <SwitchInput
                    name="scanDelivery"
                    label="Escaneo"
                    onChange={(value) => {
                      // onScan(value)
                      setStartScan(value);
                    }}
                  />
                </Col>
                <Col md={6} sm={12} style={{ whiteSpace: 'nowrap',display:"flex",textAlign:"right" }}>
                  <SwitchInput
                    name="selectAll"
                    label="Seleccionar todos"
                    onChange={(value) => onSelectAll(value)}
                  />
                </Col>
                <Col md={6} sm={12} style={{ textAlign: "right" }}>
                  {checkIfMultiplePoints ? (
                    <Space size="small">
                      <Select
                        options={destinationOptions}
                        placeholder="Destinos próximos"
                        onChange={onDestinationChange}
                        style={{ textAlign: "left", maxWidth: 400 }}
                        allowClear
                        value={selectedDestination?.value as string}
                      />
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !(
                            scopes.crear &&
                            scopes.modificar &&
                            selectedDestination &&
                            receiveTags.every((x) => x.escaneoEntrega)
                          )
                        }
                      >
                        Aceptar entrega y generar nuevo rastreo
                      </Button>
                    </Space>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        (buttonDisabled || notRecolected) &&
                        scopes.crear &&
                        scopes.modificar
                      }
                    >
                      Aceptar entrega
                    </Button>
                  )}
                </Col>
              </Fragment>
            </Row>
            {startScan && (
              <>
                <Row style={{ marginTop: 10 }}>
                  <Col md={6} sm={12} style={{ textAlign: "left" }}>
                    <TextInput
                      formProps={{
                        name: "scannLabel",
                        label: "Escanear etiqueta",
                      }}
                      autoFocus={true}
                      onChange={(e) => {
                        let claveEtiqueta = e.target.value.trim();
                        if (
                          !!claveEtiqueta.length &&
                          claveEtiqueta.length >= 12
                        ) {
                          let etiquetaActual = receiveTags.find(
                            (x) => x.recipiente.trim() === claveEtiqueta
                          );

                          if (!!etiquetaActual) {
                            onScan(true, etiquetaActual.etiquetaId);
                          } else {
                            alerts.error(
                              `La etiqueta ${claveEtiqueta} no se encuentra dentro del Número de seguimiento ${receive?.seguimiento}`
                            );
                          }
                          form.resetFields(["scannLabel"]);
                        }
                      }}
                    ></TextInput>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        ) : (
          ""
        )}
        <br />
        <ReceiveTrackingTable
          setNotScanned={setNotScanned}
          setButtonDisabled={setButtonDisabled}
          setNotRecolected={setNotRecolected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          receive={receive}
        />
      </Spin>
    </Fragment>
  );
};

export default observer(ReceiveTrackingDetail);
