import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useStore } from "../app/stores/store";
import ClinicResultsBody from "../components/clinicResults/ClinicResultsBody";
import ClinicResultsHeader from "../components/clinicResults/ClinicResultsHeader";
import "../components/clinicResults/css/estiloTabla.less";

const ClinicResults = () => {
  const { clinicResultsStore, generalStore } = useStore();
  const { scopes, exportList } = clinicResultsStore;
  const { generalFilter } = generalStore;
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    await exportList(generalFilter);
    setLoading(false);
  };

  if (!scopes.acceder) {
    return <Navigate to="/forbidden" />;
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <ClinicResultsHeader handleList={handleDownload} />
      </div>
      {/* <Divider style={{ marginTop: 0 }} className="header-divider" /> */}
      <div style={{ flex: "1 1 0%" }}>
        <ClinicResultsBody printing={loading} formValues={generalFilter} />
      </div>
    </div>
  );
};

export default observer(ClinicResults);
